import React from 'react';
import {
  faGear,
  faGasPump,
  faGaugeSimpleHigh,
  faIndianRupeeSign,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { db } from '../Firebase';
import { useNavigate } from 'react-router-dom';
import { collection, onSnapshot } from 'firebase/firestore';
import Footer from './Footer';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import SearchBar from './SearchBar';
import "../Components/Styles/Cars.scss"


function Home() {
  const [cars, setCars] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [initialCars, setInitialCars] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');


  useEffect(() => {
    setLoading(true);
    const unsub = onSnapshot(
      collection(db, 'cars'),
      (snapshot) => {
        let list = [];
        snapshot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        setCars(list);
        setInitialCars(list);
        setLoading(false);
      },
      (error) => {
        console.log(error);
      }
    );

    return () => {
      unsub();
    };
  }, []);

  const handleSearch = (searchTerm) => {
    // Filter the cars based on the search term
    const filteredCars = initialCars.filter(
      (item) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.veh_model.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setCars(filteredCars);
    if(filteredCars.length === 0){
      console.log("No results found");
    }
  };

  const clearSearch = () => {
    setSearchTerm('');
    handleSearch('');
  }



  return (
    <div className="car-listing">
      <h1>Newly Added Cars</h1>
      <SearchBar handleSearch={handleSearch} />

      <div className="cars">
        
        {cars.length > 0 ? (
          cars.map((item) => (
            <div key={item.id} className="car-item">
               <div className='car-name'>
               <h2>
                  {item.year} {item.name} {item.veh_model}
                </h2>
              </div>
              <div className='car-image-dix'><img src={item.img} alt={item.name} className="car-image" /></div>
              <div className="car-details">
                <div className='top-details'>
                    <p>
                      <FontAwesomeIcon icon={faGaugeSimpleHigh} /> {item.veh_kms_driven} km
                    </p>
                    <p>
                      <FontAwesomeIcon icon={faGasPump} /> {item.veh_fuel_type}
                    </p>
                    <p>
                      <FontAwesomeIcon icon={faGear} /> {item.veh_transmission_type}
                    </p>
                </div>
                <div className='price'>
                 <div className='price-div'>
                 Best Price
                    <p>
                      <FontAwesomeIcon icon={faIndianRupeeSign} /> {item.veh_price}
                    </p>
                  
                </div> 
                <div className='social-div'>
                  <div className='facebook'><a href={item.veh_facebook}><FontAwesomeIcon icon={faFacebook} /></a></div> 
                  <div className='instagram'><a href={item.veh_instagram}><FontAwesomeIcon icon={faInstagram} /></a></div>
                </div>
                </div>
              </div>
              <div>  <Link to={`/moredetails/${item.id}`}>
                <button className="enquire-button">More Details...</button>
              </Link>
              </div>
            </div>
          ))
        ):(
          <div className='no-cars-found'>
                <div>Currently we dont have this car</div>
                <div><button className='no-cars-found-btn' onClick={clearSearch} > Go back to Home</button></div>
            </div>
        )}
      </div>
      <Footer/>
    </div>
    
  );
}

export default Home;