import React, { useState, useEffect, version } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../Firebase';
import { collection, doc, getDoc, updateDoc, deleteDoc } from 'firebase/firestore';



const UpdateCar = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [car, setCar] = useState({
    name: '',
    year: '',
    month: '',
    reg_number: '',
    veh_transmission_type: 'Manual',
    veh_fuel_type: 'Petrol',
    veh_price: '',
    veh_ownership: 'First',
    veh_kms_driven: '',
    veh_engine_capacity: '',
    veh_spare_key: 'Available',
    veh_insurance: 'Valid',
    veh_insaurance_valid: '',
    veh_instagram: '',
    veh_facebook: '',
    veh_model:''
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const carRef = doc(db, 'cars', id);
        const carSnapshot = await getDoc(carRef);
        if (carSnapshot.exists()) {
          const carData = carSnapshot.data();
          setCar(
            { 
              name: carData.name, 
              year: carData.year,
              month: carData.month,
              reg_number: carData.reg_number,
              veh_transmission_type: carData.veh_transmission_type,
              veh_fuel_type: carData.veh_fuel_type,
              veh_price: carData.veh_price,
              veh_ownership: carData.veh_ownership,
              veh_kms_driven: carData.veh_kms_driven,
              veh_engine_capacity: carData.veh_engine_capacity,
              veh_spare_key: carData.veh_spare_key,
              veh_insurance: carData.veh_insurance,
              veh_insaurance_valid: carData.veh_insaurance_valid  || '',
              veh_instagram: carData.veh_instagram || '',
              veh_facebook: carData.veh_facebook || '',
              veh_model: carData.veh_model
            });
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [id]);

  const handleUpdate = async () => {
    
    try {
      const carRef = doc(db, 'cars', id); // Make sure the document ID is correct
      console.log('Car Reference:', carRef); // Add this line for debugging
      const updatedCar = {
        name: car.name,
        year: car.year,
        month: car.month,
        veh_model: car.veh_model,
        reg_number: car.reg_number,
        veh_fuel_type: car.veh_fuel_type,
        veh_transmission_type: car.veh_transmission_type,
        veh_ownership: car.veh_ownership,
        veh_kms_driven: car.veh_kms_driven,
        veh_spare_key: car.veh_spare_key, 
        veh_insurance: car.veh_insurance,
        veh_price: car.veh_price,
         veh_engine_capacity: car.veh_engine_capacity,
         veh_insaurance_valid : car.veh_insaurance_valid,
         veh_instagram:car.veh_instagram,
         veh_facebook:car.veh_facebook
        
        // Add other properties here
      };
      console.log('Updated Car:', updatedCar); // Add this line for debugging

      await updateDoc(carRef, updatedCar); // Ensure 'carRef' and 'updatedCar' are properly formatted
      console.log('Data updated successfully'); // Add this line for debugging

      navigate('/dashboard');
    } catch (error) {
      console.error('Error updating data:', error);
    }
  };

  const handleDelete = () => {
      navigate(`/delete/${id}`); // Use the replace option to replace the current URL without adding to the history
  };

  return (
    <div className="update-car-container">
      <h1>Update Car Information</h1>
      <form>
        <div className="form-group">
          <label>Name:</label>
          <input
            type="text"
            value={car.name}
            onChange={(e) => setCar({ ...car, name: e.target.value })}
          />
        </div>
        <div className="form-group">
          <label>Year:</label>
          <input
            type="text"
            value={car.year}
            onChange={(e) => setCar({ ...car, year: e.target.value })}
          />
        </div>
        <div className="form-group">
          <label>Month</label>
          <input
            type="text"
            value={car.month}
            onChange={(e) => setCar({ ...car,month: e.target.value })}
          />
        </div>

        <div className="form-group">
          <label>Reg Number</label>
          <input
            type="text"
            value={car.reg_number}
            onChange={(e) => setCar({ ...car, reg_number: e.target.value })}
          />
        </div>

        <div className="form-group">
        <label>Model</label>
        <input
          type="text"
          value={car.veh_model} // Fixed the typo here
          onChange={(e) => setCar({ ...car, veh_model: e.target.value })}
        />
      </div>


        <div className="form-group">
          <label>transmission_type</label>

          <select
              type="text"
              id="model"
              name="veh_transmission_type"
              value={car.veh_transmission_type}
              onChange={(e) => setCar({ ...car,veh_transmission_type: e.target.value })}
              className="form-control"
            >
              <option value="">Please Select</option>
              <option value="Automatic">Automatic</option>
              <option value="Manual">Manual</option>
            </select>
        </div>

        <div className="form-group">
          <label>veh_fuel_type</label>

          <select
              type="text"
              id="veh_fuel_type"
              name="veh_fuel_type"
              value={car.veh_fuel_type}
              onChange={(e) => setCar({ ...car,veh_fuel_type: e.target.value })}
              className="form-control"
            >
              <option value="">Please Select</option>
              <option value="Petrol" selected={car.veh_fuel_type === 'Petrol'} >Petrol</option>
              <option value="Diesel" selected={car.veh_fuel_type === 'Diesel'}>Diesel</option>
              <option value="Electric" selected={car.veh_fuel_type === 'Electric'}>Electric</option>
            </select>
        </div>

        <div className="form-group">
          <label>veh_price</label>
          <input
            type="text"
            value={car.veh_price}
            onChange={(e) => setCar({ ...car, veh_price: e.target.value })}
          />
        </div>


        <div className="form-group">
          <label>veh_ownership</label>

          <select
              type="text"
              id="veh_ownership"
              name="veh_ownership"
              value={car.veh_ownership}
              onChange={(e) => setCar({ ...car,veh_ownership: e.target.value })}
              className="form-control"
            >
                            <option value="">Please Select</option>

              <option value="First">First</option>
              <option value="Second">Second</option>
              <option value="Third">Third</option>
            </select>
        </div>

        
        <div className="form-group">
          <label>veh_kms_driven</label>
          <input
            type="text"
            value={car.veh_kms_driven}
            onChange={(e) => setCar({ ...car, veh_kms_driven: e.target.value })}
          />
        </div>


        <div className="form-group">
          <label>veh_engine_capacity</label>
          <input
            type="text"
            value={car.veh_engine_capacity}
            onChange={(e) => setCar({ ...car, veh_engine_capacity: e.target.value })}
          />
        </div>

        <div className="form-group">
          <label>veh_spare_key</label>

          <select
              type="text"
              id="veh_spare_key"
              name="veh_spare_key"
              value={car.veh_spare_key}
              onChange={(e) => setCar({ ...car,veh_spare_key: e.target.value })}
              className="form-control"
            >
                            <option value="">Please Select</option>

              <option value="Available">Available</option>
              <option value="Not Avaialbe">Not Available</option>
            </select>
        </div>

        <div className="form-group">
          <label>veh_insurance</label>

          <select
              type="text"
              id="veh_insurance"
              name="veh_insurance"
              value={car.veh_insurance}
              onChange={(e) => setCar({ ...car,veh_insurance: e.target.value })}
              className="form-control"
            >
                            <option value="">Please Select</option>

              <option value="Valid">Valid</option>
              <option value="Not Valid">Not Valid</option>
            </select>
        </div>

        <div className="form-group">
          <label>veh_insaurance_valid</label>
          <input
            type="Date"
            value={car.veh_insaurance_valid}
            onChange={(e) => setCar({ ...car, veh_insaurance_valid: e.target.value })}
          />
        </div>

        <div className="form-group">
          <label>veh_instagram</label>
          <input
            type="text"
            value={car.veh_instagram}
            onChange={(e) => setCar({ ...car, veh_instagram: e.target.value })}
          />
        </div>

        <div className="form-group">
          <label>veh_facebook</label>
          <input
            type="text"
            value={car.veh_facebook}
            onChange={(e) => setCar({ ...car, veh_facebook: e.target.value })}
          />
        </div>
        <button 
        type='button'
        className="btn btn-primary" 
        style={{background:'green'}}
         onClick={handleUpdate}>
          Update
        </button>

        <h2>Do you want to delete car </h2>
        <button className="btn btn-danger"  style={{width:'95vw'}} onClick={handleDelete}>
          Delete
        </button>
      </form>
    </div>
  );
};

export default UpdateCar;
