import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram , faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <h2>Find Us On</h2>
        <div className="social-icons">
          <a href="https://facebook.com/profile.php?id=100065659562800" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faFacebook} size="2x" />
          </a>
   
          <a href="https://instagram.com/prg_cars" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faInstagram} size="2x" />
          </a>
        </div>
        <address>
          Manish Nagar Near Nexa Service Center<br />
          Besa Road Nagpur 440023<br/>
          <a style={{textDecoration:"none" , color:'black'}} href="tel:+919766624663"> <FontAwesomeIcon icon={faPhone} /> +91 9766624663 </a> <br />
          <a style={{textDecoration:"none" , color:'black'}} href='mailto:pritam@prgcars.in'><FontAwesomeIcon icon={faEnvelope} /> pritam@prgcars.in</a>
        </address>
        <p>&copy; {new Date().getFullYear()} PRG Cars</p>
      </div>
    </footer>
  );
}

export default Footer;
