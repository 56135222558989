import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import "../Components/Styles/Search.scss"

const SearchBar = ({ handleSearch }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    handleSearch(searchTerm);
  };

  const handleClear = () => {
    setSearchTerm('');
    handleSearch('');
  };

  return (
    <div className="search-component">
      <div className="search-component-form-div">
        <form className="search-component-form" onSubmit={handleFormSubmit}>
          <div className="search-component-input">
            <input
              className='search-box'
              type="text"
              placeholder="Search your Dream car"
              value={searchTerm}
              onChange={handleInputChange}
            />
            {searchTerm && (
              <div className="clear-icon" onClick={handleClear}>
                <FontAwesomeIcon icon={faTimes}/>
              </div>
            )}
          </div>
          <div className="search-component-button">
            <button type="submit">Search</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SearchBar;
