import React from 'react';
import { Link } from 'react-router-dom';

function NavigationMenu({ isOpen, toggleMenu }) {
  const closeMenuOnClick = () => {
    if (isOpen) {
      toggleMenu();
    }
  };

  return (
    <nav className={`navigation-menu ${isOpen ? 'open' : ''}`}>
      <div className='navigation-menu-buttons'>
      <ul>
       
          <div className = 'navigation-menu-list'></div>
          <li className='button'>
            <Link to={'/'} onClick={closeMenuOnClick}>
              Home
            </Link>
          </li>
          <li className='button'>
            <Link to={'/about'} onClick={closeMenuOnClick}>
              About us
            </Link>
          </li>
          <li className='button'>
            <Link to={'/sellcar'} onClick={closeMenuOnClick}>
              Sell your car
            </Link>
          </li>
          <li className='button'>
            <Link to={'/testimonials'} onClick={closeMenuOnClick}>
              Our happy Customers
            </Link>
          </li>
    
      </ul>
      </div>
      <button onClick={toggleMenu} className="close-button"></button>
    </nav>
  );
}

export default NavigationMenu;
