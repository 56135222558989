// Loader.js

import React from 'react';


const Loader = () => {
  return (
    <>
    <div className="loader-overlay">
      <div className="loader-spinner"></div>
      <div className='loader-text'>Please Hold Tight..</div>
    </div>

    </>
  );
};

export default Loader;
