import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db, storage } from '../Firebase';
import { doc, getDoc, deleteDoc } from 'firebase/firestore';
import { getStorage, ref, deleteObject } from "firebase/storage";
import Loader from './Loader';

const DeleteCar = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [car, setCar] = useState(null);
  const [loading, setLoading] = useState(false); // Add loading state

  useEffect(() => {
    const fetchData = async () => {
      try {
        const carRef = doc(db, 'cars', id);
        const carSnapshot = await getDoc(carRef);
        if (carSnapshot.exists()) {
          const carData = carSnapshot.data();
          setCar(carData);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [id]);

  const handleDelete = async () => {
    try {
      const carRef = doc(db, 'cars', id);
      const carSnapshot = await getDoc(carRef);
      const storageRef = getStorage();

      if (carSnapshot.exists()) {
        const carData = carSnapshot.data();
        setLoading(true); // Set loading to true when the deletion process starts
        for (let i = 0; i < carData.img.length; i++) {
          const imgRef = ref(storageRef, carData.img[i]);
          await deleteObject(imgRef);
          //console.log(`Image ${i + 1} deleted`);
        }
        setLoading(false); // Set loading to false once the deletion is complete
        await deleteDoc(carRef);
        navigate('/dashboard', { replace: true });
      }
    } catch (error) {
      console.error('Error deleting data:', error);
    }
  };

  return (
    <div style={styles.container}>
      {car && (
        <div style={styles.carDetails}>
          <h2>Data of the car to be deleted:</h2>
          <p><strong>Name:</strong> {car.name}</p>
          <p><strong>Year:</strong> {car.year}</p>
          {/* Display other properties as needed */}
        </div>
      )}
      {loading ? ( // Render the loader based on the loading state
        <Loader />
      ) : (
        <div style={styles.deleteSection}>
          <h2>Do you want to delete this car?</h2>
          <button style={styles.deleteButton} onClick={handleDelete}>
            Delete
          </button>
        </div>
      )}
    </div>
  );
};

// Styles...


const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
  },
  carDetails: {
    border: '1px solid #ddd',
    borderRadius: '5px',
    padding: '10px',
    margin: '10px',
    width: '80%',
  },
  deleteSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '20px',
  },
  deleteButton: {
    backgroundColor: 'red',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    padding: '10px 20px',
    cursor: 'pointer',
  },
};

export default DeleteCar;
