import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../Firebase';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { doc, getDoc } from 'firebase/firestore';
import { Carousel } from 'react-carousel-minimal';
import { faGear, faGasPump, faGaugeSimpleHigh, faIndianRupeeSign, faUserLarge, faRoad, faKey, faShield, faPhone, faMapPin } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFontAwesomeLogoFull } from '@fortawesome/free-brands-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import Footer from './Footer';
import Warranty from './Warranty';
import Loader from './Loader';
import { collection, addDoc } from 'firebase/firestore';


const handleCall = (phoneNumber) => {
    window.open(`tel:${phoneNumber}`, '_self');
  };



const SellCarDetails = () => {
    
  const [showContactForm, setShowContactForm] = useState(false);
  const [scrollingDown, setScrollingDown] = useState(true); // Assuming user starts scrolling down
  const { id } = useParams();
  const slideNumberStyle = {
    fontSize: '20px',
    fontWeight: 'bold',
  };

  const [documentData, setDocumentData] = useState(null);
  const [imageUrls, setImageUrls] = useState([]);

  const fetchData = async () => {
    try {
      const docRef = doc(db, 'sellcars', id);
      const docSnapshot = await getDoc(docRef);
      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        setDocumentData(data);

        // Set imageUrls here when documentData is available
        if (data.img && data.img.length > 0) {
          setImageUrls(data.img);
        }
      } else {
        // Document does not exist
      }
    } catch (error) {
      console.error('Error fetching document:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      if (scrollY > window.innerHeight / 2) {
        // User has scrolled halfway down the page
        setScrollingDown(false);
      } else {
        setScrollingDown(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);



  return (
    <div>
      {documentData ? (
        <div className='vehicle-details'>
          <div className='top-column'>
            <div className='vehicle-text-details'>
              <div className='car_name_details'> <p> {documentData.year} {documentData.name} {documentData.veh_model} </p> </div> </div>

            <div className="vehicle-images">
              {imageUrls.length > 0 ? (
                <Carousel
                  data={imageUrls.map((imgUrl) => ({ image: imgUrl }))}
                  time={2000}
                  width="850px"
                  height="500px"
                  radius="10px"
                  slideNumber={true}
                  slideNumberStyle={slideNumberStyle}
                  dots={true}
                  slideBackgroundColor="darkgrey"
                  slideImageFit="cover"
                  fullscreen="true"
                  style={{
                    textAlign: 'center',
                    maxWidth: '850px',
                    maxHeight: '500px',
                    margin: '40px auto',
                  }}
                />
              ) : (
                <p>No images available</p>
              )}
            </div>
          </div>
          <div className='mid-column'>
            <div className='car_price_details'>
              <div className='asking_price_label'><p> Expected Price </p></div>
              <div className='asking_price'><p> <FontAwesomeIcon icon={faIndianRupeeSign} />{documentData.veh_price}</p> </div>
            </div>
            <div className='specifications'>
              <div className='specifications_heading'>Key Specifications</div>
              <div className='car_details'>
                <div className='car_kms'>
                  <p> <FontAwesomeIcon icon={faGaugeSimpleHigh} /> Kms Driven</p>
                  <p>  {documentData.veh_kms_driven} km </p>
                </div>
                <div className='car_fuel'>
                  <p><FontAwesomeIcon icon={faGasPump} /> Fuel Type</p>
                  <p>  {documentData.veh_fuel_type}  </p>
                </div>
                <div className='car_transmission'>
                  <p> <FontAwesomeIcon icon={faGear} /> Transmission</p>
                  <p>{documentData.veh_transmission_type} </p>
                </div>
                <div className='car_owner'>
                  <p><FontAwesomeIcon icon={faUserLarge} /> Owners</p>
                  <p>{documentData.veh_ownership}</p>
                </div>
                <div className='car_reg_number'>
                  <p> <FontAwesomeIcon icon={faRoad} /> Reg Number</p>
                  <p>{documentData.reg_number}</p>
                </div>
                <div className='car_keys_available'>
                  <p> <FontAwesomeIcon icon={faKey} /> Spare Key</p>
                  <p> {documentData.veh_spare_key} </p>
                </div>
                <div className='car_reg_number'>
                  <p> <FontAwesomeIcon icon={faShield} /> Insaurance</p>
                  <p> {documentData.veh_insaurance_valid} </p>
                </div>
                <div className='car_reg_number'>
                  <p> <FontAwesomeIcon icon={faShield} /> Engine Capacity</p>
                  <p> {documentData.veh_engine_capacity} cc</p>
                </div>
                <div className='car_reg_number'>
                  <p> <FontAwesomeIcon icon={faPhone} />Phone </p>
                  <p> {documentData.veh_customer_number}</p>
                </div>
                <div className='car_reg_number'>
                  <p> <FontAwesomeIcon icon={faMapPin} />Address</p>
                  <p> {documentData.veh_customer_address}</p>
                </div>

              </div>

              
            </div>

          </div>
          <div className='bottom-column'>
        {scrollingDown && (
          <div className='call-us'>
            <div className='call-us-button'>
           
              <button  
              onClick={() => handleCall(documentData.veh_customer_number)}>
                <FontAwesomeIcon icon={faPhone} /> Call Customer
              </button>
            </div>
            
          </div>
        )}
      </div>
      <Footer />
      
    </div>
      )
        : (
          <Loader />
        )}
    </div>
  );
};

export default SellCarDetails;