import React , {useState , useEffect}from 'react'
import { storage, db } from '../Firebase'
import { useParams, useNavigate, Form } from 'react-router-dom'
import Loader from './Loader'
import { getDownloadURL, ref, uploadBytes, uploadBytesResumable } from 'firebase/storage'
import { addDoc, collection, serverTimestamp, snapshotEqual } from 'firebase/firestore'

const InitialState = {
    name : '',
    veh_model: '',
    year : '',
    month :'',
    reg_number: '',
    veh_fuel_type: '',
    veh_transmission_type: '',
    veh_ownership: '',
    veh_kms_driven: '',
    veh_spare_key: '', 
    veh_insurance: '',
    veh_price: '',
    veh_engine_capacity: '',
    veh_insaurance_valid : '',
    veh_instagram:'',
    veh_facebook:''
}

const AddCar = () => {

  const [data, setData] = useState([]);
  const [files, setFiles] = useState(null);
  const [progress, setProgress] = useState(null);
  const [error, setError] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [isUploading, setIsUploading] = useState(false); // Track image uploading
  const { name, year } = data; // for destructuring
  const navigate = useNavigate();
  const imageUrls = [];

  
  useEffect(() => {
    const uploadFiles = async () => {
      setIsUploading(true); // Start image uploading
      for (let i = 0; i < files.length; i++) {
        try {
          const name = new Date().getTime() + files[i].name; // Generate a unique name
          const storageRef = ref(storage, name);
          const uploadTask = uploadBytesResumable(storageRef, files[i]);

          uploadTask.on(
            'state_changed',
            (snapshot) => {
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              setProgress(progress);

              switch (snapshot.state) {
                case 'paused':
                  console.log('Upload is paused');
                  break;
                case 'running':
                  console.log('Upload is running');
                  break;
                default:
                  break;
              }
            },
            (error) => {
              console.log('Error during upload:', error);
            },
            async () => {
              const downloadUrl = await getDownloadURL(uploadTask.snapshot.ref);
              imageUrls.push(downloadUrl);

              // Update data with the new download URL
            }
          );

          // Wait for this file's upload to complete before moving to the next one
          await uploadTask;
        } catch (error) {
          console.error('Error uploading file:', error);

          // Handle the error as needed
        }
        setData((prevData) => ({ ...prevData, img: imageUrls }));
      }
      setIsUploading(false); // All images uploaded
    };

    if (files && files.length > 0) {
      uploadFiles();
    }
  }, [files]);

  
  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const validate = () => {
    let errors = {};
    if (!name) {
      errors.name = 'Name is required';
    }
    if (!year) {
      errors.year = 'Year is required';
    }
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let errors = validate();
    if (Object.keys(errors).length) return setError(errors);

    setIsSubmit(true);
    // Wait until all images are uploaded before submitting the form
    while (isUploading) {
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait for 1 second
    }

    await addDoc(collection(db, 'cars'), {
      ...data,
      timestamp: serverTimestamp(),
    });
    navigate('/');
  };

  const handleValidation = (e) => {
    const { name, value } = e.target;
    if (value.trim() === '') {
      e.target.setCustomValidity(`${name} is required`);
    } else {
      e.target.setCustomValidity('');
    }
  };





 

  return (
    <div className="container">
      <h1>Vehicle Information Form</h1>

      {isSubmit ? (
        <Loader />
      ) : (
        <form onSubmit={handleSubmit} className="form">
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Enter Name"
              value={data.name}
              onChange={handleChange}
              className="form-control"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="year">Year</label>
            <input
              type="text"
              id="year"
              name="year"
              placeholder="Enter Year"
              value={data.year}
              onChange={handleChange}
              className="form-control"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="month">Month</label>
            <input
              type="text"
              id="month"
              name="month"
              placeholder="Enter month"
              value={data.month}
              onChange={handleChange}
              className="form-control"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="veh_model">model</label>
            <input
              type="text"
              id="veh_model"
              name="veh_model"
              placeholder="Enter month"
              value={data.veh_model}
              onChange={handleChange}
              className="form-control"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="reg_number">Reg Number</label>
            <input
              type="text"
              id="reg_number"
              name="reg_number"
              placeholder="Enter month"
              value={data.reg_number}
              onChange={handleChange}
              required
              className="form-control"
            />
          </div>

          <div className="form-group">
            <label htmlFor="veh_transmission_type">Transmission Type</label>
            <select
              type="text"
              id="model"
              name="veh_transmission_type"
              value={data.veh_transmission_type}
              onChange={handleChange}
              className="form-control"
              required
            >
              <option value="">Please Select</option>
              <option value="Automatic">Automatic</option>
              <option value="Manual">Manual</option>
            </select>
          </div>


          <div className="form-group">
            <label htmlFor="veh_fuel_type">Fuel Type</label>
            <select
              type="text"
              id="veh_fuel_type"
              name="veh_fuel_type"
              value={data.veh_fuel_type}
              onChange={handleChange}
              className="form-control"
              required
            >
              <option value="">Please Select</option>
              <option value="Petrol">Petrol</option>
              <option value="Diesel">Diesel</option>
              <option value="Electric">Electric</option>
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="veh_price">Price</label>
            <input
              type="text"
              id="veh_price"
              name="veh_price"
              placeholder="Enter price"
              value={data.veh_price}
              onChange={handleChange}
              className="form-control"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="veh_ownership">Owners</label>
            <select
              type="number"
              id="veh_ownership"
              name="veh_ownership"
              value={data.veh_ownership}
              onChange={handleChange}
              required
              className="form-control"
            >
              <option value="">Please Select</option>

              <option value="First">First</option>
              <option value="Second">Second</option>
              <option value="Third">Third</option>
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="veh_kms_driven">KM Driven</label>
            <input
              type="number"
              id="veh_kms_driven"
              name="veh_kms_driven"
              placeholder="KM Driven"
              value={data.veh_kms_driven}
              onChange={handleChange}
              required
              className="form-control"
            />
          </div>

          <div className="form-group">
            <label htmlFor="veh_engine_capacity">Engine CC</label>
            <input
              type="number"
              id="veh_engine_capacity"
              name="veh_engine_capacity"
              placeholder="Engine Capacity"
              value={data.veh_engine_capacity}
              onChange={handleChange}
              className="form-control"
              required
            />
          </div>


          <div className="form-group">
            <label htmlFor="veh_spare_key">Spare Key</label>
            <select
              type="text"
              id="veh_spare_key"
              name="veh_spare_key"
              value={data.veh_spare_key}
              onChange={handleChange}
              className="form-control"
              required
            >
              <option value="">Please Select</option>
              <option value="Available">Available</option>
              <option value="Not Avaialbe">Not Available</option>
            </select>
          </div>


          <div className="form-group">
            <label htmlFor="veh_insurance">Insaurance</label>
            <select
              type="text"
              id="veh_insurance"
              name="veh_insurance"
              value={data.veh_insurance}
              onChange={handleChange}
              required
              className="form-control"
            >
              <option value="">Please Select</option>
              <option value="Valid">Valid</option>
              <option value="Not Valid">Not Valid</option>
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="veh_insaurance_valid">Insaurance Valid Till</label>
            <input
              type="Date"
              id="veh_insaurance_valid"
              name="veh_insaurance_valid"
              placeholder="Engine Capacity"
              value={data.veh_insaurance_valid}
              onChange={handleChange}
              className="form-control"
              
            />
          </div>

          <div className="form-group">
            <label htmlFor="veh_instagram">Instagram Link</label>
            <input
              type="text"
              id="veh_instagram"
              name="veh_instagram"
              placeholder="Instagram URL"
              value={data.veh_instagram}
              onChange={handleChange}
              className="form-control"
              
            />
          </div>

          <div className="form-group">
            <label htmlFor="veh_facebook">Facebook Link</label>
            <input
              type="text"
              id="veh_facebook"
              name="veh_facebook"
              placeholder="Facebook URL"
              value={data.veh_facebook}
              onChange={handleChange}
              className="form-control"
              
            />
          </div>
          
          <div className="form-group">
            <label htmlFor="photo">Photo</label>
            <input
              type="file"
              id="photo"
              multiple
              required
              onChange={(e) => setFiles(e.target.files)}
              className="form-control"
            />
          </div>
          {isUploading ? (
            <Loader /> // Show loader while uploading
          ) : (
            <button
              type="submit"
              disabled={progress !== null && progress < 100}
              className="btn btn-primary"
            >
              Add Vehicle
            </button>
          )}
        </form>
      )}
    </div>
  );
};


export default AddCar