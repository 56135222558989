import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { db } from '../Firebase';
import { collection, getDoc, getDocs } from 'firebase/firestore';
import { getDownloadURL } from 'firebase/storage';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import "../Components/Styles/Login.scss"

const StyledAccordion = styled(Accordion)({
  marginTop : '10px',
  marginBottom: '10px',
  backgroundColor: 'yellow',
  boxShadow : 'none',
  borderRadius : '10px',
  '@media (max-width: 768px)': {
    width: '100%',
  },
});

const StyledAccordionSummary = styled(AccordionSummary)({
  backgroundColor: '#e0e0e0',
});

const StyledAccordionDetails = styled(AccordionDetails)({
  backgroundColor: '#f5f5f5',
});

const StyledAccordionTypography = styled(Typography)({
  fontSize: '18px',
  fontWeight: '800',
  fontFamily:'poppins'
});


const handleCall = (phoneNumber) => {
  window.open(`tel:${phoneNumber}`, '_self');
};

const Dashboard = () => {
  const [carData, setCarData] = useState([]);
  const [contactData, setContactData] = useState([]);
  const [sellCarData, setSellCarData] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(localStorage.getItem('isAuthenticated') === 'true'); // Retrieve authentication status from local storage
  const [expanded, setExpanded] = useState(false);

  // Simulating authentication based on a username and password
  const username = 'adminprg160923';
  const password = 'Axi123#$%PRg';
  const [inputUsername, setInputUsername] = useState('');
  const [inputPassword, setInputPassword] = useState('');

  const handleLogin = () => {
    if (inputUsername === username && inputPassword === password) {
      setIsAuthenticated(true);
      localStorage.setItem('isAuthenticated', 'true'); // Store authentication status in local storage
    } else {
      alert('Invalid username or password');
    }
  };


  const handleLogout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem('isAuthenticated'); // Remove authentication status from local storage
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };



  useEffect(() => {
    if (isAuthenticated) {
      const fetchData = async () => {
        try {
          const carsSnapshot = await getDocs(collection(db, 'cars'));
          const data = carsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
          setCarData(data);

          const contactsSnapshot = await getDocs(collection(db, 'contacts'));
          const contacts = contactsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
          setContactData(contacts);

          const sellCarSnapshot = await getDocs(collection(db, 'sellcars'));
          const sellcars = sellCarSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
          setSellCarData(sellcars);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    }
  }, [isAuthenticated]);

  return (
      <div className="container">
      <h1>Dashboard</h1>

      {isAuthenticated ? (
        <div>
          
        <div>
          <StyledAccordion expanded={expanded === 'panel1'} onChange={handleAccordionChange('panel1')}>
      <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <StyledAccordionTypography>Add A New Car</StyledAccordionTypography>
        </AccordionSummary>
        <AccordionDetails>
 
              <div className="accordion-content">
                <Link to={'/addcar'}>
                  <button className="btn btn-primary">Add Car</button>
                </Link>
          </div>
        </AccordionDetails>
      </StyledAccordion>
      <StyledAccordion expanded={expanded === 'panel2'} onChange={handleAccordionChange('panel2')}>
      <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <StyledAccordionTypography> Update Existing cars</StyledAccordionTypography>
        </AccordionSummary>
        <AccordionDetails>
              <div className="accordion-content">
              <table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Year</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {carData.map((car) => (
                      <tr key={car.id}>
                        <td>{car.name}</td>
                        <td>{car.year}</td>
                        <td>
                          <Link to={`/update/${car.id}`}>
                            <button className="btn btn-primary">Update</button>
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
          </div>
        </AccordionDetails>
      </StyledAccordion>

      <StyledAccordion expanded={expanded === 'panel3'} onChange={handleAccordionChange('panel3')}>
      <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <StyledAccordionTypography>  Customer leads</StyledAccordionTypography>
        </AccordionSummary>
        <AccordionDetails>
              <div className="accordion-content">
              <table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Number</th>
                      <th>Query</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {contactData.map((contact) => (
                      <tr key={contact.id}>
                        <td>{contact.name}</td>
                        <td>{contact.phone}</td>
                        <td>{contact.message}</td>
                        <td>
                            <button className="btn btn-primary" 
                            onClick={() => handleCall(contact.phone)}
                            >Call</button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>          
                </div>
        </AccordionDetails>
      </StyledAccordion>

      <StyledAccordion expanded={expanded === 'panel4'} onChange={handleAccordionChange('panel4')}>
      <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <StyledAccordionTypography> Sell car leads</StyledAccordionTypography>
        </AccordionSummary>
        <AccordionDetails>
              <div className="accordion-content">
              <table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Year</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sellCarData.map((sellcars) => (
                      <tr key={sellcars.id}>
                        <td>{sellcars.name}</td>
                        <td>{sellcars.year}</td>
                        <td>
                          <Link to={`/sellcarview/${sellcars.id}`}>
                            <button className="btn btn-primary">view</button>
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>       
                </div>
        </AccordionDetails>
      </StyledAccordion>
          <button className = 'dashboard-logout' onClick={handleLogout}>Logout</button> {/* Logout button */}
          </div>
        </div>

        
      ) : (
        <div className='login'>
          <h1>Login with a user</h1>
          <input
            type="text"
            className='login-username'
            placeholder="Username"
            value={inputUsername}
            onChange={(e) => setInputUsername(e.target.value)}
          />
          <input
            type="password"
            className='login-password'
            placeholder="Password"
            value={inputPassword}
            onChange={(e) => setInputPassword(e.target.value)}
          />
          <button className='login-button' onClick={handleLogin} >Login</button>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
