import React from 'react';
import "../Components/Styles/About.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInbox, faIndianRupeeSign, faMapPin, faPhone } from '@fortawesome/free-solid-svg-icons';
import { Carousel } from 'react-carousel-minimal';
import Footer from './Footer';

const AboutUs = () => {
  const images = [
      {
        image: "/img/firstshowrom2.jpeg",
        caption : "Our First Showroom"
      },
      {
        image: "/img/FirstShworoom.jpeg",
        caption : "2021 - Our First Showroom"
      },
      {
        image: "/img/firstshowroom3.jpeg",
        caption : "Our First Showroom"
      },
      {
        image: "/img/hundredcar.jpeg",
        caption : "2022 - 100th Car Delivered"
      },
      {
        image: "/img/newshworoom1.jpeg",
        caption : "2023 - Our new Showroom"
      },
      {
        image: "/img/newshworoom1.jpeg",
     
      },
      {
        image: "/img/newshworrom3.jpeg",
     
      },
      {
        image: "/img/newshowroom4.jpeg",
       
      },
      {
        image: "/img/newshowroom5.jpeg",
      },
      {
        image: "/img/secondshworoom7.jpeg",
      },
     
  ];

  const captionStyle = {
    fontSize: '2em',
    fontWeight: 'bold',
  }
  const slideNumberStyle = {
    fontSize: '20px',
    fontWeight: 'bold',
    color : 'grey'
  }

  return (
    <div className="about-us-container">
      <div className='heading'> 
          <h1>About Us</h1>
      </div>
      <div className='images-about-us'>
      <Carousel
                  data = {images} 
                  time={1000}
                  width="850px"
                  height="500px"
                  radius="10px"
                  slideNumber={true}
                  slideNumberStyle={slideNumberStyle}
                  dots={true}
                  slideBackgroundColor="darkgrey"
                  slideImageFit="cover"
                  fullscreen="true"
                  style={{
                    textAlign: 'center',
                    maxWidth: '850px',
                    maxHeight: '500px',
                    margin: '40px auto',
                  }}
                  
                />
      </div>
      <div className="timeline">
        "Looking for the best place to buy used cars in MANISH NAGAR? PRG CARS PVT. LTD. is your answer for getting competitively priced, certified and reliable second hand cars in MANISH NAGAR. PRG Cars is where you will find a large selection of second hand cars in NAGPUR. If you are seeking a wide range of used cars at reasonable rates in Quality Cars, you should go for PRG Cars Sales without a doubt. From attractive used car valuation and a diverse range of certified and verified cars to smooth customer service and several other benefits, PRG Cars will help you out with your car purchases in Nagpur. PRG CARS is one of the most trusted brands for 2nd hand cars in MANISH NAGAR."
      </div>

      <div className='write-to-us'>
        <h1> Write to us</h1>
        <p>
       <p>
        <a style={{textDecoration:"none" , color:'black'}} href='mailto:pritam@prgcars.in'>  <FontAwesomeIcon icon={faInbox} /> pritam@prgcars.in </a>
       </p> 
       </p>
       <p> 
       <a style={{textDecoration:"none" , color:'black'}} href='mailto:juhi@prgcars.in'> <FontAwesomeIcon icon={faInbox} /> juhi@prgcars.in  </a> </p>
      </div>

      <div className='call-us-about'>
        <h1> Contact Numbers </h1>
        <a style={{textDecoration:"none" , color:'black'}} href="tel:9766624663"> <p> <FontAwesomeIcon icon={faPhone} /> 9766624663 </p> </a>
        <a style={{textDecoration:"none" , color:'black'}}href="tel:9503717901"> <p> <FontAwesomeIcon icon={faPhone} /> 9503717901 </p> </a>
      </div>

      <div className='locate-us-about'>
        <h1> Locate us</h1>
        <p> <FontAwesomeIcon icon={faMapPin} /> Near Nexa Service Center Manish Nagar 440010 Nagpur</p>
      </div>
      <Footer/>
    </div>
    
  );
};

export default AboutUs;
