import logo from './logo.svg';
import './App.css';
import Header from './Components/Header';
import "./Style.scss"
import Home from './Components/Home';
import { Route , Routes, Router, BrowserRouter, HashRouter } from 'react-router-dom';
import VehicleDetails from './Components/VehicleDetails';
import Footer from './Components/Footer';
import Test from './Components/Test';
import AddCar from './Components/AddCar';
import { updateDoc } from 'firebase/firestore';
import Cars from './Components/Cars';
import MoreDetails from './Components/MoreDetails';
import AddCarUpdate from './Components/AddCarUpdate';
import AboutUs from './Components/AboutUs';
import Loader from './Components/Loader';
import UpdateCar from './Components/UpdateCar';
import SellCar from './Components/SellCar';
import Dashboard from './Components/Dashboard';
import SellCarView from './Components/SellCarView';
import DeleteCar from './Components/DeleteCar';
import Testimonials from './Components/Testimonials';
import DesktopDashboard from './Components/DesktopDashboard';
import CustomerDashboard from './Components/CustomerDashboard';

function App() {
  
  return (
    <>
    <HashRouter>
    <switch>
      <Header/>
      <div className='main'>

      <Routes>
        <Route path='/' Component={Home}/>
        <Route  path='/moredetails/:id/' Component={MoreDetails} />
        <Route path='/footer' Component={Footer}/>
        <Route path='/cars' Component={Cars}/>
        <Route path='/addcar' Component={AddCarUpdate}/>
        <Route path='/about' Component={AboutUs}/>
        <Route path="/update/:id" Component={UpdateCar} />
        <Route path="/sellcar" Component={SellCar} />
        <Route path="/dashboard" Component={Dashboard} />
        <Route path='/sellcarview/:id' Component={SellCarView}/>
        <Route path="/delete/:id" Component={DeleteCar}/>
        <Route path='testimonials' Component={Testimonials}/>
        <Route path='/customerdashboard' Component={CustomerDashboard}/>
        </Routes>

      </div>
      
    </switch>    
    </HashRouter>
    </>
  )
}

export default App;
