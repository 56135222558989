import React from 'react';
import "../Components/Styles/Testimonials.scss"


const Testimonial = ({ name, message, image }) => {
  return (
    <div className="testimonial">
       <div className='testimonial-name'><h3>{name}</h3></div>
      <div className='testimonial-image'><img src={image} alt={`Portrait of ${name}`} /></div>
      <div className='testimonial-msg'><p>{message}</p></div>
    </div>
  );
};

export default Testimonial;
