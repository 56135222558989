import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { db } from '../Firebase';
import { collection, getDoc, getDocs } from 'firebase/firestore';
import { getDownloadURL } from 'firebase/storage';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import "../Components/Styles/Login.scss"


const handleCall = (phoneNumber) => {
  window.open(`tel:${phoneNumber}`, '_self');
};

const CustomerDashboard = () => {
  const [carData, setCarData] = useState([]);
  const [contactData, setContactData] = useState([]);
  const [sellCarData, setSellCarData] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(localStorage.getItem('isAuthenticated') === 'true'); // Retrieve authentication status from local storage
  const [expanded, setExpanded] = useState(false);

  // Simulating authentication based on a username and password
  const username = 'admin';
  const password = 'password';
  const [inputUsername, setInputUsername] = useState('');
  const [inputPassword, setInputPassword] = useState('');

  const handleLogin = () => {
    if (inputUsername === username && inputPassword === password) {
      setIsAuthenticated(true);
      localStorage.setItem('isAuthenticated', 'true'); // Store authentication status in local storage
    } else {
      alert('Invalid username or password');
    }
  };


  const handleLogout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem('isAuthenticated'); // Remove authentication status from local storage
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };



  useEffect(() => {
    if (isAuthenticated) {
      const fetchData = async () => {
        try {
          const carsSnapshot = await getDocs(collection(db, 'cars'));
          const data = carsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
          setCarData(data);

          const contactsSnapshot = await getDocs(collection(db, 'contacts'));
          const contacts = contactsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
          setContactData(contacts);

          const sellCarSnapshot = await getDocs(collection(db, 'sellcars'));
          const sellcars = sellCarSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
          setSellCarData(sellcars);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    }
  }, [isAuthenticated]);

  return (
      <div className="container">
      <h1>Dashboard</h1>

      {isAuthenticated ? (
    
          
        <div>   
              <div className="accordion-content">
              <table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Number</th>
                      <th>Query</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {contactData.map((contact) => (
                      <tr key={contact.id}>
                        <td>{contact.name}</td>
                        <td>{contact.phone}</td>
                        <td>{contact.message}</td>
                        <td>
                            <button className="btn btn-primary" 
                            onClick={() => handleCall(contact.phone)}
                            >Call</button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>          
                </div>
                </div>

        
      ) : (
        <div className='login'>
          <h1>Login with a user</h1>
          <input
            type="text"
            className='login-username'
            placeholder="Username"
            value={inputUsername}
            onChange={(e) => setInputUsername(e.target.value)}
          />
          <input
            type="password"
            className='login-password'
            placeholder="Password"
            value={inputPassword}
            onChange={(e) => setInputPassword(e.target.value)}
          />
          <button className='login-button' onClick={handleLogin} >Login</button>
        </div>
      )}
    </div>
  );
};

export default CustomerDashboard;
