// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import {getStorage} from "firebase/storage"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAgf1zC-16nxpEnEycWIQvRz-28yQ8wUBw",
  authDomain: "prgcars-a7548.firebaseapp.com",
  projectId: "prgcars-a7548",
  storageBucket: "prgcars-a7548.appspot.com",
  messagingSenderId: "774294443756",
  appId: "1:774294443756:web:ae6341df5206dbe02037c9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const storage = getStorage(app);