import React from 'react';
import { faCalendarDay , faMoneyBill , faCircleCheck} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "../Components/Styles/Warranty.scss"

function Warranty() {
  return (
    <section className='warranty-main'>
      <div className='warranty-heading'>
        <h2> Buy with Confidence</h2>
      </div>
      <div className='warranty-points'>
        <div className='warranty-photo'>
          <FontAwesomeIcon icon={faCalendarDay} />
        </div>
        <div className='warranty-details'>
          <h1>12 Months Warranty</h1>
          <p>Complete after-sales support on all covered parts for a stress-free drive</p>
        </div>
      </div>
      <div className='warranty-points'>
        <div className='warranty-photo'>
          <FontAwesomeIcon icon={faMoneyBill} />
        </div>
        <div className='warranty-details'>
          <h1>Easy Financing Options</h1>
          <p>Become eligible for Minimum Down Payment via EMIs of up to 60 months and instant loans</p>
        </div>
      </div>
      <div className='warranty-points'>
        <div className='warranty-photo'>
          <FontAwesomeIcon icon={faCircleCheck} />
        </div>
        <div className='warranty-details'>
          <h1>140 Quality Checks</h1>
          <p>Inspected across 140 parameters and refurbished by auto-experts, our cars are ready for the road</p>
        </div>
      </div>
    </section>
  );
}

export default Warranty;
