import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { db } from '../Firebase';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { doc, getDoc } from 'firebase/firestore';
import { Carousel } from 'react-carousel-minimal';
import { faGear, faGasPump, faGaugeSimpleHigh, faIndianRupeeSign, faUserLarge, faRoad, faKey, faShield, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFontAwesomeLogoFull } from '@fortawesome/free-brands-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import Footer from './Footer';
import Warranty from './Warranty';
import Loader from './Loader';
import { collection, addDoc } from 'firebase/firestore';
import "../Components/Styles/MoreDetails.scss"
import "../Components/Styles/Overlay.scss"



const ContactFormOverlay = ({ onClose }) => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const docRef = await addDoc(collection(db, 'contacts'), {
        name: name,
        phone: phone,
        message: message
      });
      console.log('Document written with ID: ', docRef.id);
      // Clear form data
      setName('');
      setPhone('');
      setMessage('');
      onClose();
    } catch (error) {
      console.error('Error adding document: ', error);
    }
  };

  return (
    <div className="overlay overlay-desktop">
      <div className="overlay-content">
        <div className='overlay-header'>
          <div className='overlay-text'>
            <p>Connect with us</p>
          </div>
          <div className='overlay-close'>
            <span className="close-button" 
              onClick={onClose}>
              &times;
            </span>
          </div>
        </div>
        
       
        <div className='overlay_form'>
        <form onSubmit={handleFormSubmit}>
          <div className='name-input'>
              <input
                type="text"
                placeholder="Your Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
          </div>
          <div className='email-input'>
              <input
                type="tel"
                placeholder="Your Phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                required
              />
          </div>
          <div className='text-area-input'>
              <textarea
                placeholder="Your Requirement"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
              ></textarea>
          </div>
        
          <button className = 'overlay-submit-btn' type="submit">Submit</button>
          <div className='overlay-p'>
              our represetative will contact you shortly
          </div>
        </form>

        <div className='overlay-sell'>
            <hr></hr>
            <p> want to sell your old car ?</p>
            <Link to={'/sellcar'}>
              <button className = 'overlay-sellcar-submit-btn ' type="submit">Get a Free Quote</button>
            </Link>
        </div>  
        </div>

      </div>
    </div>
  );
};


const MoreDetails = () => {
  const [showContactForm, setShowContactForm] = useState(false);
  const { id } = useParams();
  const slideNumberStyle = {
    fontSize: '20px',
    fontWeight: 'bold',
  };

  const [documentData, setDocumentData] = useState(null);
  const [imageUrls, setImageUrls] = useState([]);

  const fetchData = async () => {
    try {
      const docRef = doc(db, 'cars', id);
      const docSnapshot = await getDoc(docRef);
      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        setDocumentData(data);

        // Set imageUrls here when documentData is available
        if (data.img && data.img.length > 0) {
          setImageUrls(data.img);
        }
      } else {
        // Document does not exist
      }
    } catch (error) {
      console.error('Error fetching document:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  return (
    <div>
      {documentData ? (
        <div className='vehicle-details'>
          <div className='top-column'>
            <div className='vehicle-text-details'>
              <div className='car_name_details'> <p> {documentData.year} {documentData.name} {documentData.veh_model} </p> </div> </div>

            <div className="vehicle-images">
              {imageUrls.length > 0 ? (
                <Carousel
                  data={imageUrls.map((imgUrl) => ({ image: imgUrl }))}
                  time={2000}
                  width="850px"
                  height="500px"
                  radius="10px"
                  slideNumber={true}
                  slideNumberStyle={slideNumberStyle}
                  dots={true}
                  slideBackgroundColor="darkgrey"
                  slideImageFit="cover"
                  fullscreen="true"
                  style={{
                    textAlign: 'center',
                    maxWidth: '850px',
                    maxHeight: '500px',
                    margin: '40px auto',
                  }}
                />
              ) : (
                <p>No images available</p>
              )}
            </div>
          </div>
          <div className='mid-column'>
            <div className='car_price_details'>
              <div className='asking_price_label'><p> Asking Price </p></div>
              <div className='asking_price'><p> <FontAwesomeIcon icon={faIndianRupeeSign} />{documentData.veh_price}</p> </div>
            </div>

      
      <div className='bottom-column'>
          <div className='call-us'>
            <div className='call-us-button'>
              <button onClick={() => setShowContactForm(true)}>
                <FontAwesomeIcon icon={faPhone} /> Call us
              </button>
            </div>
            <div className='whatsapp-us-button'>
              <a href='https://api.whatsapp.com/send?phone=9766624663'>
                <button>
                  <FontAwesomeIcon icon={faWhatsapp} />
                </button>
              </a>
            </div>
          </div>
      </div>

            <div className='specifications'>
              <div className='specifications_heading'>Key Specifications</div>
              <div className='car_details'>
                <div className='car_kms'>
                  <p> <FontAwesomeIcon icon={faGaugeSimpleHigh} /> Kms Driven</p>
                  <p>  {documentData.veh_kms_driven} km </p>
                </div>
                <div className='car_fuel'>
                  <p><FontAwesomeIcon icon={faGasPump} /> Fuel Type</p>
                  <p>  {documentData.veh_fuel_type}  </p>
                </div>
                <div className='car_transmission'>
                  <p> <FontAwesomeIcon icon={faGear} /> Transmission</p>
                  <p>{documentData.veh_transmission_type} </p>
                </div>
                <div className='car_owner'>
                  <p><FontAwesomeIcon icon={faUserLarge} /> Owners</p>
                  <p>{documentData.veh_ownership}</p>
                </div>
                <div className='car_reg_number'>
                  <p> <FontAwesomeIcon icon={faRoad} /> Reg Number</p>
                  <p>{documentData.reg_number}</p>
                </div>
                <div className='car_keys_available'>
                  <p> <FontAwesomeIcon icon={faKey} /> Spare Key</p>
                  <p> {documentData.veh_spare_key} </p>
                </div>
                <div className='car_reg_number'>
                  <p> <FontAwesomeIcon icon={faShield} /> Insaurance</p>
                  <p> {documentData.veh_insaurance_valid} </p>
                </div>
                <div className='car_reg_number'>
                  <p> <FontAwesomeIcon icon={faShield} /> Engine Capacity</p>
                  <p> {documentData.veh_engine_capacity} cc</p>
                </div>
              </div>

              
            </div>
          </div>
          <div className='warrenty'>
                <Warranty />
              </div>
      <Footer />
      {showContactForm && (
        <ContactFormOverlay onClose={() => setShowContactForm(false)} />
      )}
    </div>
      )
        : (
          <Loader />
        )}
    </div>
  );
};

export default MoreDetails;