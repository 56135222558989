import React, { useEffect, useRef } from 'react';
import Testimonial from './Testimonial';
import "../Components/Styles/Testimonials.scss"
import Footer from './Footer';


const Testimonials = () => {
  const testimonialsRef = useRef(null);

  const testimonialsData = [
    {
      id: 1,
      name: 'Tarul Jayant Raut',
      message: '"Outstanding service and quality car - PRG Cars Deliver, last year i purchased my first car and the experience was exceptional. The owners, a wonderful couple, provided outstanding customer service and the car was delivered in impeccable condition. I highly recomment PRG cars for their friendly approach, Quality Vehicle and commitment to customer satisfaction "',
      image: 'img/Tarul.jpeg',
    },
    {
      id: 2,
      name: 'Abhijeet Kumar',
      message: '"PRG Cars owned by Mr. Pritam is doing amazing work in 2nd hand car SUV segment. He always has a amazing collection on cars. Luckly I got my 1st Thar through his Manish Nagar outlet. Keep Rocking and fullfiling the need for speed of common people"',
      image: 'img/Thar.jpeg',
    },
    {
      id: 3,
      name: 'Dr. Rahul Pethe',
      message: '"It was nice experience with all the staff members. I personally recomment such valuable service support. Our Family members are very happy and thankful to Gulhane ji and his staff members. I purchased Alto 800 Relationship and bonding with customers faith on valuable customer Recomment to all those who want to purchase second hand cars. Blindly follow and purchase as a new family member"',
     image: 'img/Phate.jpeg',
    },
  
    // Add more testimonials as needed
  ];

  useEffect(() => {
    const testimonialElements = testimonialsRef.current.querySelectorAll('.testimonial');
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('show');
        }
      });
    }, {
      threshold: 0.5 // Adjust threshold as needed
    });

    testimonialElements.forEach((element) => {
      observer.observe(element);
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    
    <div ref={testimonialsRef} className="testimonials-container">
      <div className='testimonial-heading'><h1>Our 200+ Happy Customers</h1></div>
      <div className='testimonial-heading'><h2>Customers review</h2></div>
      {testimonialsData.map((testimonial) => (
        <Testimonial
          key={testimonial.id}
          name={testimonial.name}
          message={testimonial.message}
          image={testimonial.image}
        />
      ))}
      <div className='testimonial-heading'><h1>Spreading Happiness via Great service</h1></div>
      <Footer/>  
    </div>
    
  );
};

export default Testimonials;
